@import "colors";
.mat-mdc-card{
  box-shadow: 0 1px 2px rgba(63, 63, 68, 0.15);
  margin-top: 1rem;
  .mat-mdc-card-title {
    font-size: 16px!important;
    letter-spacing: -0.05px;
    line-height: 20px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif !important;
    color: #263238;
  }

  .mat-card-header-text {
    margin: 5px;
  }
  .mat-mdc-card-header {
    display: flex;
    padding-bottom: 12px;
    border-bottom: 1px solid $light-grey-bg;
  }
  .mat-mdc-card-title-group{
    width: 100%;
  }
  .mat-mdc-card-title-group .mat-icon{
    margin-top:2px;
    color:$dark-blue
  }
}
